"use client"
import styles from "./index.module.scss";
import { useParams } from "next/navigation";

export default function Footer({ logo }: { logo: string | null | undefined }) {

    let logoPath = '/greenballLogo.svg';
    if (logo) {
        logoPath = logo;
    }
    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.logo}>
                <img src={logoPath} alt="logo" />
                    </div>
            </div>
        </footer>
    );
}
