'use client';

import { QueryClient, QueryClientProvider } from '@/utils/@tanstack/react-query';
import React, { useState } from 'react';

import { defaultOptions } from '@/utils/Global';
import { Locale } from "@/i18n/i18n-config";
import { ReactQueryDevtools } from "@/utils/@tanstack/react-query-devtools";

const QueryProvider = ({ children, locale }: { children: React.ReactNode; locale: Locale }) => {
  const [queryClient] = useState(() => new QueryClient(defaultOptions(locale)));
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
export default QueryProvider;
