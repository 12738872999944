import type { ErrorInterface } from "@/utils/errorHandler";

import styles from "./list.module.scss";
import Translate from "@/i18n";

const ErrorList = ({ error }: { error?: ErrorInterface | string | null }) => {
  if (!error) return null;

  let msg: React.ReactNode | string = "";

  if (typeof error !== "string" && Array.isArray(error.message)) {
    msg = error.message.map((item, index) => <li key={index}>{item}</li>);
  } else if (typeof error !== "string" && error.message.message) {
    // @ts-ignore: Unreachable code error
    msg = <li>{error.message.message}</li>;
  } else if (typeof error === "string") {
    msg = <li>{error}</li>;
  }

  return (
    <div className={styles.error}>
      <ul>{msg}</ul>
    </div>
  );
};

export default ErrorList;
