"use client"
import { useGetQuery } from "@/hooks";
import styles from "./index.module.scss";
import { UseQueryResult } from "@/utils/@tanstack/react-query";
import InitInterface from "@/types/initInterface";
import { useParams, usePathname, useRouter } from "next/navigation";
import { Activity } from "@/utils/constants";
import cn from 'classnames';


export default function Header({ logo }: { logo: string | undefined }) {
    const pathName = usePathname();
    const router = useRouter()
    const { data: init } = useGetQuery(['init']) as UseQueryResult<InitInterface>;
    const params: { activity: string } = useParams();
    let titleVal = 'enterPin';


    if (pathName.includes('/terms')) {
        titleVal = 'terms'
    } else if (init && params.activity === undefined && pathName === '/') {
        titleVal = 'selectActivity'
    } else if (params.activity) {
        titleVal = params.activity;
    } else if (pathName.includes('/performance')) {
        titleVal = 'performance'
    } else if (pathName.includes('/fan')) {
        titleVal = 'download'
    }


    let logoPath = '/greenballLogo.svg';
    if (logo) {
        logoPath = logo;
    }
    return (
        <header className={styles.wrapper} onClick={() => router.push('/')}>
            <div className={styles.logo}>
                <img src={logoPath} alt="logo" />
            </div>
            <div className={styles.title}>
                {Activity[titleVal]}
            </div>
            <div className={cn(styles.logo, styles.right)}>
                <img src={logoPath} alt="logo" />
            </div>
        </header>
    );
}
