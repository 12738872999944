import cn from 'classnames';
import type { MouseEventHandler, ReactNode } from 'react';

import styles from './button.module.scss';

const Button = ({
  type = 'interaction',
  actionType = 'button',
  children,
  onClick = (e: MouseEventHandler<HTMLButtonElement>) => e,
  disabled = false,
  fixedSize = false,
  postMessage = false,
  size = 'medium',
  color,
  style,
}: {
  type?: 'interaction' | 'accent' | 'invert' | 'warning' | 'tag' | 'invertLight';
  actionType?: 'button' | 'submit' | 'reset';
  postMessage?: boolean;
  children: ReactNode;
  onClick?: Function;
  disabled?: boolean;
  fixedSize?: boolean;
  size?: 'small' | 'medium' | 'large';
  color?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <button
      className={cn(
        styles.button,
        disabled && styles.disabled,
        type === 'accent' && styles.accent,
        type === 'invert' && styles.invert,
        type === 'warning' && styles.warning,
        type === 'tag' && styles.tag,
        type === 'interaction' && styles.interaction,
        type === 'invertLight' && styles.invertLight,
        size === 'small' && styles.small,
        fixedSize && styles.fixedSize,
        postMessage && styles.postMessage
      )}
      type={actionType}
      style={{ background: type === 'tag' ? color : 'auto', ...style }}
      onClick={(e) => !disabled && onClick(e)}
    >
      {children}
    </button>
  );
};

export default Button;
